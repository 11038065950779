import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs/Rx';
import { map, timestamp } from 'rxjs/operators';
import { workItem } from 'app/models/project-model';
import * as firebase from 'firebase';
import { Task, MomentTask } from 'app/models/task-model';

@Injectable({
  providedIn: 'root'
})
export class DiaryService {

  userId: string;
  user: firebase.User;
  myDocument: AngularFirestoreDocument<{}>;
  actNumber: number;
  standards: Observable<workItem[]>;
  stdArray: any[];
  stdNo: number;
  viewActions: Observable<workItem[]>;
  myActionItems: workItem[];
  viewDayActions: any[];
  allActions: any;
  selectedActions: any;
  today2: string;
  weeklyTasks: Task[];
  OutstandingTasks: any[];
  CurrentTAsks: any[];
  UpcomingTAsks: any[];
  ShortTermTAsks: any[];
  LongTermTAsks: any[];
  MediumTermTAsks: any[];
  tasks: Observable<Task[]>;
  allMyTasks: Task[];

  constructor(public afAuth: AngularFireAuth, private afs: AngularFirestore) {
    this.afAuth.user.subscribe(user => {
      this.userId = user.uid;
      this.user = user;
      this.callData();
      console.log('diary service');
      // console.log(this.user);
      
    });
  }

  callData() {
    // console.log('diary service');
    this.myDocument = this.afs.collection('Users').doc(this.userId);
    const currentDate = moment(new Date()).format('L');
    const timeId = String(moment(currentDate).format('DD-MM-YYYY'));
    const dayTag = { name: timeId, id: timeId }
    const viewActions = this.myDocument.collection('WeeklyActions').snapshotChanges().pipe(map(actions => actions.map(a => {
      const data = a.payload.doc.data() as any; const id = a.payload.doc.id; return { id, ...data }; })
    ));
    viewActions.subscribe((wrks) => {
      wrks.forEach(element => {
        if (element.targetQty !== '' && element.start !== '' && element.end !== '') {
          this.myDocument.collection('WeeklyActions').doc(element.id).update({'targetQty': '', 'start': '', 'end': ''});
        } else if (element.start !== '' || element.end !== '') {
          this.myDocument.collection('WeeklyActions').doc(element.id).update({'start': '', 'end': ''});
        } else if (element.targetQty !== '') {
          this.myDocument.collection('WeeklyActions').doc(element.id).update({'targetQty': ''});
        }
        // console.log(element.name, 'cleared');
      })
    });
    this.myDocument.collection('DayActions').valueChanges().subscribe(dts => {
      const exist = dts.find((item) => item.name === dayTag.name);
      if (exist) {
        const i = dts.findIndex(n => n.name === dayTag.name);
        // console.log('exist', exist);
        // this.viewTodayActionQuery(currentDate);
        this.addPrevData();
      } else {
        // console.log('not existing');
        this.myDocument.collection('DayActions').doc(timeId).set(dayTag).then(() => {
          // this.viewTodayActionQuery(currentDate);
          this.addPrevData();
        });
      }
    })
    const userRef = this.myDocument.collection<Task>('WeeklyTasks', ref => ref
    .where('champion.id', '==',this. userId ).where('complete', '==', false ));
    userRef.snapshotChanges().pipe( map(b => b.map(a => {
      const data = a.payload.doc.data() as Task;  const id = a.payload.doc.id;
      return { id, ...data };
    }))).subscribe(wksTasks => {
      this.weeklyTasks = wksTasks;
    })

    this.tasks = this.myDocument.collection<Task>('tasks', ref => ref.orderBy('start', 'desc').where('champion.id', '==', this.userId))
      .snapshotChanges().pipe(
        map(b => b.map(a => {
          const data = a.payload.doc.data() as MomentTask;
          // console.log(data.champion.name);
          const id = a.payload.doc.id;
          const myTaskData = data;
          myTaskData.when = moment(data.start, 'YYYY-MM-DD').fromNow().toString();
          myTaskData.then = moment(data.finish, 'YYYY-MM-DD').fromNow().toString();
          // this.categorizedTasks.push(this.myTaskData);
          return { id, ...data };
        }))
      );

    this.tasks.subscribe((tasks) => {
      // console.log(tasks);
      this.OutstandingTasks = [];
      this.CurrentTAsks = [];
      this.UpcomingTAsks = [];
      this.ShortTermTAsks = [];
      this.MediumTermTAsks = [];
      this.LongTermTAsks = [];
      tasks.forEach(data => {
        const today = moment(new Date(), 'YYYY-MM-DD');
        const t2 = moment().add(3, 'w');
        // if (data.update === undefined || data.update === '' || data.update === undefined || moment(data.update).isBefore(t2) ) {
        if (data.champion.id === this.userId) {
          if (moment(data.start).isSameOrBefore(today) && moment(data.finish).isSameOrAfter(today)) {
            // currentWorkItems
            this.CurrentTAsks.push(data);
          };
          // outstanding tasks
          if (moment(data.finish).isBefore(today)) {
            this.OutstandingTasks.push(data);
          };
          // Upcoming tasks
          if (moment(data.start).isAfter(today)) {
            this.UpcomingTAsks.push(data);
            if (moment(data.start).isSameOrBefore(today.add(3, 'month'))) {
              this.ShortTermTAsks.push(data);
            } else if (moment(data.start).isSameOrBefore(today.add(12, 'month'))) {
              this.MediumTermTAsks.push(data);
            } else if (moment(data.start).isAfter(today.add(12, 'month'))) {
              this.LongTermTAsks.push(data)
              // console.log('long term Tasks' + ' ' + this.LongTermTAsks);
            }
            // console.log(this.OutstandingTasks);
          };
        }
        // }
      });
      this.allMyTasks = tasks;
    });
  }

  addPrevData() {
    const ntimeId = String(moment(new Date()).subtract(1, 'd').format('DD-MM-YYYY'));
    const n1timeId = String(moment(new Date()).subtract(1, 'd').format('DD-MM-YYYY'));
    const n2timeId = String(moment(new Date()).subtract(1, 'd').format('DD-MM-YYYY'));
    const timeId = String(moment(new Date()).format('DD-MM-YYYY'));
    const viewActionsRef = this.myDocument;
    // console.log(checkPeriod);
    const currentDate = moment(new Date()).format('L');
    const today = moment(new Date(), 'YYYY-MM-DD');
    // console.log(timeId);
    const today2 = currentDate;
    this.today2 = currentDate;
    const d = new Date();
    const nHrs = Math.floor(d.getHours());
    const D1 = this.myDocument.collection('DayActions').doc(n1timeId).collection('WeeklyActions').valueChanges();
    const D2 = this.myDocument.collection('DayActions').doc(n2timeId).collection('WeeklyActions').valueChanges();
    let d1DATA = [];
    let d2DATA = [];
    D1.subscribe(d1data =>{ d1DATA = d1data;});
    D1.subscribe(d2data =>{ d2DATA = d2data;});
    this.myDocument.collection('DayActions').doc(ntimeId).collection('WeeklyActions').valueChanges().subscribe(pdts => {
      if (pdts.length > 0) {
        this.myDocument.collection('DayActions').doc(timeId).collection('WeeklyActions').valueChanges().subscribe(dN => {
          pdts.forEach(secondElem => {
            if (secondElem.complete === false) {
              secondElem.targetQty = ''; secondElem.start = '' ; secondElem.end = '';
              const exist = dN.find((item) => item.id === secondElem.id);
              if (exist) {
                // console.log(exist.name, ' already exists');
              } else {
                this.myDocument.collection('DayActions').doc(timeId).collection('WeeklyActions').doc(secondElem.id).set(secondElem).then(() => {
                  // console.log(exist.name, ' added');
                });
              }
            }
          });
        })
      } else if (d1DATA.length > 0) {
        this.myDocument.collection('DayActions').doc(timeId).collection('WeeklyActions').valueChanges().subscribe(dN => {
          d1DATA.forEach(secondElem => {
            if (secondElem.complete === false) {
              secondElem.targetQty = ''; secondElem.start = '' ; secondElem.end = '';
              const exist = dN.find((item) => item.id === secondElem.id);
              if (exist) {
                // console.log(exist.name, ' already exists');
              } else {
                this.myDocument.collection('DayActions').doc(timeId).collection('WeeklyActions').doc(secondElem.id).set(secondElem).then(() => {
                  // console.log(exist.name, ' added');
                });
              }
            }
          });
        })
      } else if (d2DATA.length > 0) {
        this.myDocument.collection('DayActions').doc(timeId).collection('WeeklyActions').valueChanges().subscribe(dN => {
          d2DATA.forEach(secondElem => {
            if (secondElem.complete === false) {
              secondElem.targetQty = ''; secondElem.start = '' ; secondElem.end = '';
              const exist = dN.find((item) => item.id === secondElem.id);
              if (exist) {
                // console.log(exist.name, ' already exists');
              } else {
                this.myDocument.collection('DayActions').doc(timeId).collection('WeeklyActions').doc(secondElem.id).set(secondElem).then(() => {
                  // console.log(exist.name, ' added');
                });
              }
            }
          });
        })
      } else {
        console.log('Go to task 24/7 , Implementation/ Daily Diary and add tasks to your Daily Diary if nun create a new Plan');
      }
    })

    /* 999999999999999999999999999999999 Daily Diary Implemenetation 6666666666666666666666666666666666666 */

    this.allActions = viewActionsRef.collection<workItem>('WeeklyActions', ref => ref
      // .orderBy('taskName', 'asc')
      .orderBy('UpdatedOn', 'desc')
      .where('complete', '==', false) ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as workItem; const id = a.payload.doc.id; return { id, ...data };
      }))
    );
    this.myDocument.collection('DayActions').doc(timeId).collection<workItem>('WeeklyActions', ref => ref
      .where('complete', '==', false) .where('selectedWork', '==', true)).valueChanges().subscribe(tsubs => {
        if(tsubs.length > 0) {
          const ss = tsubs.length;
          if (nHrs <= 10) {
            this.myDocument.collection('DayActions').doc(timeId).update({ 'no_Actions': ss });
          } else if (nHrs > 10) {
            this.myDocument.collection('DayActions').doc(timeId).ref.get().then((day) => {
              if (day.exists) {
                if (ss < day.data().no_Actions) {
                  console.log('Todays no_Actions not updated');
                } else {
                  this.myDocument.collection('DayActions').doc(timeId).update({ 'no_Actions': ss }).then(() => {
                    console.log('Todays no_Actions not updated');
                  });               
                }
              }
            })
          } else {
            console.log('Daily Diary empty');
          }
        }
      }
    );
    this.viewDayActions = [];
    const viewDayActions = [];
    // let viewDayActs = [];
    const compCollection = this.afs.collection('Enterprises');
    const projCollection = this.myDocument.collection('projects');
  
    const myDc = this.myDocument;
    this.allActions.subscribe((actions) => {
      this.selectedActions = actions;
      actions.forEach(element => {
        if (element.type === 'planned') {
          if (element.classification.name === 'Work' && element.companyId !== '') {
            if ((element.taskId !== '' && element.taskId !== undefined) || element.taskId !== '' && element.taskId !== '') {
              viewActionsRef.collection<Task>('tasks').doc(element.taskId).ref.get().then((tsk) => {
                if (tsk.exists) {
                  if (tsk.data().name !== undefined) {
                    element.taskName = tsk.data().name;
                  }
                  element.companyName = tsk.data().companyName;
                  element.projectName = tsk.data().projectName;
                  if (element.companyId !== '' && element.companyName === '') {
                    compCollection.doc(element.companyId).ref.get().then((cmp) => {
                      if (cmp.exists) {
                        element.companyName = cmp.data().name;
                      }
                    }).catch(err => {
                      console.log(err + ' ' + 'No Company Name found' + ' ' + element.name + ' ' + element.Id);
                    });
                  }
                  if (element.projectId !== '' && element.projectName === '') {
                    projCollection.doc(element.projectId).ref.get().then((prj) => {
                      if (prj.exists) {
                        element.projectName = prj.data().name;
                      }
                    }).catch(err => {
                      console.log(err + ' ' + 'No Company Name found' + ' ' + element.name + ' ' + element.Id);
                    });
                  }
                }
              }).catch(err => {
                console.log(err + ' ' + 'Task notFound for' + ' ' + element.name + ' ' + element.Id);
                // element.taskName = 'Task notFound';
              });
            }
          } else {
            if ((element.taskId !== '' && element.taskId !== undefined) || element.taskId !== '' && element.taskId !== '') {
              viewActionsRef.collection<Task>('tasks').doc(element.taskId).ref.get().then(function (tsk) {
                if (tsk.exists) {
                  if (tsk.data().name !== undefined) {
                    element.taskName = tsk.data().name;
                  }
                }
              })
            }
          }
        }
        if (element.selectedWeekWork === true) {
          if (moment(element.startDate).isSameOrBefore(this.today2) && element.complete === false) {
            viewDayActions.push(element);
          }
          if (element.startDate === '' && element.complete === false) {
            viewDayActions.push(element);
            // console.log(element.name);
          }
        } else if (moment(element.startDate).isSameOrBefore(this.today2) && element.complete === false) {
          viewDayActions.push(element);
        }
        viewActionsRef.collection('DayActions').doc(timeId).collection<workItem>('WeeklyActions').valueChanges().subscribe(mn =>{
          // viewDayActs = [];
          viewDayActions.forEach(secondElem=>{
            const exist = mn.find((item) => item.id === secondElem.id);
            const i = viewDayActions.findIndex((item) => item.id === secondElem.id);
            const e = mn.findIndex((item) => item.id === secondElem.id);
            if (e > -1){
              viewDayActions[i] = exist;
              // viewDayActs.push(exist);
            } else {
              // viewDayActs.push(secondElem);
            }
            this.viewDayActions = viewDayActions;
          });
          // this.viewDayActions = viewDayActs;
          this.viewDayActions.sort((a: workItem, b: workItem) => moment(a.UpdatedOn).unix() - moment(b.UpdatedOn).unix());
          // console.log(this.viewDayActions);
        })
      });
    });
    return this.viewDayActions;
  }

  getStdArr(userId) {
    const usdRef = this.afs.collection('Users').doc(userId);
    this.standards = usdRef.collection('myStandards').snapshotChanges().pipe(map(b => b.map(a => {
      const data = a.payload.doc.data() as workItem; const id = a.payload.doc.id;
      data.startDate = moment(data.startDate, 'MM-DD-YYYY').format('LL');
      data.endDate = moment(data.endDate, 'MM-DD-YYYY').format('LL');
      return { id, ...data };
    })));
    let stdArray = [];
    this.standards.subscribe((actions) => {
      this.stdArray = stdArray = [];
      actions.forEach(element => { if (element.selectedWork === true) { stdArray.push(element); } });
      this.stdNo = actions.length;
      this.stdArray = stdArray;
    });
    return this.stdArray
  }

  getActArr(userId) {
    const timeId = String(moment(new Date()).format('DD-MM-YYYY'));
    const userDocRef = this.afs.collection('Users').doc(userId);
    this.viewActions = userDocRef.collection('DayActions').doc(timeId).collection('WeeklyActions').snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as workItem; const id = a.payload.doc.id;
        return { id, ...data };
      })
    ));
    this.viewActions.subscribe((actions) => {
      this.actNumber = 0
      this.myActionItems = this.myActionItems = [];
      actions.forEach(data => {
        const element = data;
          if (element.selectedWork && element.complete === false) {
          this.myActionItems.push(element);
        } 
      })
    });
    console.log(this.myActionItems);
    return this.myActionItems;
  }
}
